// prettier-ignore
// Align by RegEx (extension): value|label|teamId|reportTypes
const REPORTS = [
  {    value: 'beet-revenue',          label: 'Beet Revenue',            teamId: '',   reportType: 'agency' },
  {    value: 'team-golden-revenue',   label: 'Team Golden Revenue',     teamId: '5',  reportType: 'client_team' },
  {    value: 'team-ruby-queen',       label: 'Team Ruby Queen Revenue', teamId: '6',  reportType: 'client_team' },
  {    value: 'team-wonder',           label: 'Team Wonder Revenue',     teamId: '7',  reportType: 'client_team' },
  {    value: 'team-avalanche',        label: 'Team Avalanche Revenue',  teamId: '9',  reportType: 'client_team' },
  {    value: 'ct-earth-revenue',      label: 'CT Earth Revenue',        teamId: '1',  reportType: 'channel_team' },
  {    value: 'ct-cultivator-revenue', label: 'CT Cultivator Revenue',   teamId: '2',  reportType: 'channel_team' },
  {    value: 'ct-nutrition-revenue',  label: 'CT Nutrition Revenue',    teamId: '3',  reportType: 'channel_team' },
  {    value: 'ct-butterfly-revenue',  label: 'CT Butterfly Revenue',    teamId: '4',  reportType: 'channel_team' },
  {    value: 'ct-bee-revenue',        label: 'CT Bee Revenue',          teamId: '10', reportType: 'channel_team' },
  // { value: 'st-biotech-revenue',    label: 'ST Biotech Revenue',      teamId: '8',  reportType: 'team' },
];

// prettier-ignore
// Align by RegEx (extension): value|label|itemsName|reportTypes|chart|dataMultiple|tableExclude|chartExclude
const REPORT_VIEWS = [
  { value: 'organisation', label: 'Total',            itemsName: 'Agency',       reportTypes: ['agency', 'client_team', 'channel_team'], chart: true,  dataMultiple: false, tableExclude: [],                    chartExclude: [] },
  { value: 'consulting',   label: 'Consulting',       itemsName: 'Agency',       reportTypes: ['agency', 'client_team', 'channel_team'], chart: true,  dataMultiple: false, tableExclude: [],                    chartExclude: ['goal_amount'] },
  { value: 'tools',        label: 'Tools & Licenses', itemsName: 'Tool/License', reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true,  tableExclude: ['reported'],          chartExclude: [] },
  { value: 'client_team',  label: 'Teams',            itemsName: 'Team',         reportTypes: ['agency'],                                chart: true,  dataMultiple: true,  tableExclude: [],                    chartExclude: [] },
  { value: 'channel_team', label: 'Channel Teams',    itemsName: 'Team',         reportTypes: ['agency'],                                chart: true,  dataMultiple: true,  tableExclude: [],                    chartExclude: [] },
  { value: 'channel',      label: 'Channels',         itemsName: 'Channel',      reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true,  tableExclude: ['goal'],              chartExclude: [] },
  { value: 'service',      label: 'Services',         itemsName: 'Service',      reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true,  tableExclude: ['goal'],              chartExclude: [] },
  { value: 'consultant',   label: 'Consultants',      itemsName: 'Consultant',   reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true,  tableExclude: ['goal'],              chartExclude: [] },
  { value: 'client',       label: 'Clients',          itemsName: 'Client',       reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true,  tableExclude: ['goal', 'potential'], chartExclude: [] },
  { value: 'clients_billable', label: 'Clients Billable', itemsName: 'Client',   reportTypes: ['agency', 'client_team', 'channel_team'], chart: false, dataMultiple: true, tableExclude: [],                    chartExclude: [] },
];

const DEFAULT_CHART_OPTIONS = {
  dot: 'goal_amount',
  bar: 'billable_amount',
  line: 'potential_amount',
};

export { REPORTS, REPORT_VIEWS, DEFAULT_CHART_OPTIONS };
